const GlobalConstants = {
  color: {},
  breakpoint: {
    large: 1440,
    medium: 1024,
    tablet: 768,
    smallTablet: 675,
    mobile: 425,
    smallMobile: 350,
  },
};

export default GlobalConstants;
